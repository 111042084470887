import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { AddServiceContactsDrawer } from './AddServiceContactsDrawer';
import { AssociateContactToClientDrawer } from './AssociateContactToClientDrawer';
import { AssociateFolderToClientDrawer } from './AssociateFolderToClientDrawer';
import { AssociateResourceToClientDrawer } from './AssociateResourceToClientDrawer';
import { ClientContactIntakeDrawer } from './ClientContactIntakeDrawer';
import { ClientIntakeDrawer } from './ClientIntakeDrawer';
import { ClientUpdateDrawer } from './ClientUpdateDrawer';
import { ClientUserGroupDrawer } from './ClientUserGroupDrawer';
import { CreateCustomFieldsDrawer } from './CreateCustomFieldDrawer';
import LinkFoldersToClientsModal from './LinkFoldersToClients';
import { SuggestedContactsDrawer } from './SuggestedContactsDrawer';
import { UpdateCustomFieldsDrawer } from './UpdateCustomFieldDrawer';

export function addOverlays(piral: PiletApi) {
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client:intake',
		props => {
			return <ClientIntakeDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '500px',
			},
		}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-contact:intake',
		props => {
			return <ClientContactIntakeDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-update',
		props => {
			return <ClientUpdateDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:service-contacts:add',
		props => {
			return <AddServiceContactsDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-associate-resources:intake',
		props => {
			return <AssociateResourceToClientDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-associate-contacts:intake',
		props => {
			return <AssociateContactToClientDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-associate-folder:intake',
		props => {
			return <AssociateFolderToClientDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:suggested-contacts',
		props => {
			return <SuggestedContactsDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:custom-fields:create',
		props => {
			return <CreateCustomFieldsDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:custom-fields:update',
		props => {
			return <UpdateCustomFieldsDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);

	piral.registerModal(
		'urn:sfmodal:client-mgt-pilet:alias-folder',
		props => <LinkFoldersToClientsModal {...props} />,
		{}
	);

	piral.registerDrawer(
		'urn:sfdrawer:client-mgt-pilet:client-user-group',
		props => {
			return <ClientUserGroupDrawer {...props} />;
		},
		{
			drawerProps: {
				width: '400px',
			},
		}
	);
}
