import React from 'react';
import type { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { ClientPrincipalSelectorParams } from '../components/ClientPrincipalSelector/types';

export type ClientPrincipalSelectorProps =
	ExtensionComponentProps<ClientPrincipalSelectorParams>;

const LazyClientPrincipalSelector = React.lazy(
	() => import('../components/ClientPrincipalSelector')
);
export const ClientPrincipalSelectorExtension: React.FC<
	ClientPrincipalSelectorProps
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-principal-selector" />}>
			<LazyClientPrincipalSelector {...props} />
		</React.Suspense>
	);
};
