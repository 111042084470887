import React from 'react';
import { ExtensionComponentProps } from '@sharefiledev/sharefile-appshell';
import { PrivateGroupDataProviderExtensionSlotParameters } from '../components/GroupDataProvider/types';

const LazyClientManagementGroupDataExtensionSlotComponent = React.lazy(
	() => import('../components/GroupDataProvider')
);

export const ClientManagementGroupDataExtensionSlotComponent: React.FC<
	ExtensionComponentProps<PrivateGroupDataProviderExtensionSlotParameters>
> = ({ piral, params }) => {
	return (
		<React.Suspense fallback={<div data-testid="loading-clientmgt-groupdata-provider" />}>
			<LazyClientManagementGroupDataExtensionSlotComponent
				params={params}
				piral={piral}
			/>
		</React.Suspense>
	);
};
