import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyCreateCustomFieldsDrawer = React.lazy(
	() => import('../components/CreateCustomFieldsDrawer')
);
export const CreateCustomFieldsDrawer: React.FC<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:custom-fields:create'>
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-create-fields-drawer" />}>
			<LazyCreateCustomFieldsDrawer initialParameters={props} />
		</React.Suspense>
	);
};
