import React from 'react';
import { OverlayDrawerComponentProps } from '@sharefiledev/sharefile-appshell';

const LazyClientUserGroupDrawer = React.lazy(
	() => import('../components/ClientUserGroup')
);
export const ClientUserGroupDrawer: React.FC<
	OverlayDrawerComponentProps<'urn:sfdrawer:client-mgt-pilet:client-user-group'>
> = props => {
	return (
		<React.Suspense fallback={<div data-testid="loading-client-user-group-drawer" />}>
			<LazyClientUserGroupDrawer initialParameters={props} />
		</React.Suspense>
	);
};
