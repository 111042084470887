import React from 'react';
import { PiletApi } from '@sharefiledev/sharefile-appshell';
import { BlockNames } from './block.types';

export function addBlocks(piletApi: PiletApi) {
	const LazyListClients = React.lazy(() => import('./ListClients'));
	piletApi.registerExtension(BlockNames.ListClients, props => (
		<React.Suspense fallback={<div data-testid="loading-list-clients" />}>
			<LazyListClients {...props} />
		</React.Suspense>
	));

	const LazyViewClient = React.lazy(() => import('./ViewClient'));
	piletApi.registerExtension(BlockNames.ViewClient, props => (
		<React.Suspense fallback={<div data-testid="loading-view-client" />}>
			<LazyViewClient {...props} />
		</React.Suspense>
	));

	const LazyClientIntake = React.lazy(() => import('./ClientIntake'));
	piletApi.registerExtension(BlockNames.ClientIntake, props => (
		<React.Suspense fallback={<div data-testid="loading-client-intake" />}>
			<LazyClientIntake {...props} />
		</React.Suspense>
	));

	const LazyClientContactIntake = React.lazy(() => import('./ClientContactIntake'));
	piletApi.registerExtension(BlockNames.ClientContactIntake, props => (
		<React.Suspense fallback={<div data-testid="loading-client-intake" />}>
			<LazyClientContactIntake {...props} />
		</React.Suspense>
	));

	const LazyClientUpdate = React.lazy(() => import('./ClientUpdate'));
	piletApi.registerExtension(BlockNames.ClientUpdate, props => (
		<React.Suspense fallback={<div data-testid="loading-client-update" />}>
			<LazyClientUpdate {...props} />
		</React.Suspense>
	));

	const LazyContactsTable = React.lazy(() => import('./ContactsTable'));
	piletApi.registerExtension(BlockNames.ContactsTable, props => (
		<React.Suspense fallback={<div data-testid="loading-contacts-table" />}>
			<LazyContactsTable {...props} />
		</React.Suspense>
	));

	const LazyClientRequests = React.lazy(() => import('./ClientRequests'));
	piletApi.registerExtension(BlockNames.ClientRequests, props => (
		<React.Suspense fallback={<div data-testid="loading-client-requests" />}>
			<LazyClientRequests {...props} />
		</React.Suspense>
	));

	const LazyClientContactsWidget = React.lazy(() => import('./ClientContactsWidget'));
	piletApi.registerExtension(BlockNames.ClientContactsWidget, props => (
		<React.Suspense fallback={<div data-testid="loading-client-contacts-widget" />}>
			<LazyClientContactsWidget {...props} />
		</React.Suspense>
	));

	const LazyServiceContactsWidget = React.lazy(() => import('./ServiceContactsWidget'));
	piletApi.registerExtension(BlockNames.ServiceContactsWidget, props => (
		<React.Suspense fallback={<div data-testid="loading-service-contacts-widget" />}>
			<LazyServiceContactsWidget {...props} />
		</React.Suspense>
	));

	const LazyClientAssociateResources = React.lazy(
		() => import('./ClientAssociateResources')
	);
	piletApi.registerExtension(BlockNames.ClientAssociateResourcesTagRenderer, props => (
		<React.Suspense fallback={<div data-testid="loading-client-associate-resources" />}>
			<LazyClientAssociateResources {...props} />
		</React.Suspense>
	));

	const LazySuggestedContactsWidget = React.lazy(
		() => import('./SuggestedContactsWidget')
	);
	piletApi.registerExtension(BlockNames.SuggestedContactsWidget, props => (
		<React.Suspense fallback={<div data-testid="loading-suggested-contacts-widget" />}>
			<LazySuggestedContactsWidget {...props} />
		</React.Suspense>
	));

	const LazySuggestedClientsWidget = React.lazy(() => import('./SuggestedClientsWidget'));
	piletApi.registerExtension(BlockNames.SuggestedClients, props => (
		<React.Suspense fallback={<div data-testid="loading-suggested-clients-widget" />}>
			<LazySuggestedClientsWidget {...props} />
		</React.Suspense>
	));

	const LazyClientDetailsWidget = React.lazy(() => import('./ClientDetailsWidget'));
	piletApi.registerExtension(BlockNames.ClientDetailsWidget, props => (
		<React.Suspense fallback={<div data-testid="loading-client-details-widget" />}>
			<LazyClientDetailsWidget {...props} />
		</React.Suspense>
	));

	const LazyClientRequestsWidget = React.lazy(() => import('./ClientRequestsWidget'));
	piletApi.registerExtension(BlockNames.ClientRequestsWidget, props => (
		<React.Suspense fallback={<div data-testid="loading-client-requests-widget" />}>
			<LazyClientRequestsWidget piletApi={piletApi} {...props.params} />
		</React.Suspense>
	));

	const LazyClientsDataProvider = React.lazy(() => import('./ClientsDataProvider'));
	piletApi.registerExtension(BlockNames.ClientsDataProvider, props => (
		<React.Suspense fallback={<div data-testid="loading-clients-data-provider" />}>
			<LazyClientsDataProvider {...props} />
		</React.Suspense>
	));
}
