import React from 'react';
import { PageComponentProps } from '@sharefiledev/sharefile-appshell';

const AsyncLegacyTokenInterceptor = React.lazy(
	() => import('../components/LegacyTokenInterceptor')
);

const LazyClientConfigurationPage = React.lazy(
	() => import('../components/ClientConfiguration')
);

export const ClientConfigurationPage: React.FC<PageComponentProps> = ({
	piral,
	...componentProps
}) => {
	return (
		<React.Suspense fallback={<div data-testid="loading-legacy-token-interceptor" />}>
			<AsyncLegacyTokenInterceptor piral={piral} {...componentProps}>
				<LazyClientConfigurationPage />
			</AsyncLegacyTokenInterceptor>
		</React.Suspense>
	);
};
